.verticalStoreLinksContainer {
  display: flex;
  flex-direction: row;

  @media (--screen-md) {
    flex-direction: column;
  }
}

.horizontalStoreLinksContainer {
  display: flex;
  flex-direction: row;
}

.verticalPlayStoreButton {
  margin-left: var(--space-2-5x);

  @media (--screen-md) {
    margin-left: 0;
    margin-top: var(--space-3x);
  }
}

.horizontalPlayStoreButton {
  margin-left: var(--space-2-5x);
}

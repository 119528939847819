.header {
  --anchor-weight: var(--font-weight-semibold);
  --anchor-font-size: var(--font-size-body);
  --anchor-padding: var(--space-3x) var(--space-5x);
  --anchor-text-color-dark-default: var(--thank-you-close-button-color);
  font-size: var(--font-size-sm);
  background: var(--thank-you-header-background-color);
  color: var(--thank-you-header-sm-text-color);
  height: 93px;
  z-index: var(--z-index-2x);
  width: 100%;
  padding: var(--space-5x);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: sticky;
  top: 0;
  min-height: 156px;

  @media (--screen-sm) {
    min-height: 120px;
  }

  @media (--screen-md) {
    border-top-left-radius: var(--modal-panel-border-radius);
    border-top-right-radius: var(--modal-panel-border-radius);
    min-height: 136px;
  }
}

.headerWithTitle {
  --font-family-headline: var(--font-family-body);
  --heading-font-weight: var(--font-weight-semibold);
  --heading-font-size: var(--font-size-xl);
  --heading-color: var(--thank-you-header-text-color);
  --heading-padding: 0;
  flex-direction: column;
  height: auto;
  align-items: center;
  padding: var(--space-10x) var(--space-16x) var(--space-6x);
  text-align: center;

  @media (--screen-md) {
    padding: var(--space-10x) var(--space-20x);
  }
}

.heading {
  color: var(--thank-you-header-text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--space-3x) 0 0;
  gap: var(--space-2x);
  opacity: 1;
  max-height: var(--space-8x);
}

.hideHeading {
  max-height: 0;
  opacity: 0;
  padding: 0;
  transition: opacity 600ms ease-in-out, max-height 600ms ease-in-out,
    padding 600ms ease-in-out;
}

.checkIconWrapper {
  font-size: var(--font-size-md);
  background: var(--thank-you-check-mark-background-color);
  color: var(--color-white);
  min-width: var(--space-5x);
  min-height: var(--space-5x);
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.similarProperties {
  --heading-margin: 0 0 var(--space-3x) 0;
  --heading-font-size: var(--font-size-md);
  --listing-card-info-section-height: 100%;
  --listing-card-padding: 0;
  --listing-card-min-height: 275px;
  --listing-card-skeleton-height: 401px;
  --listing-card-skeleton-carousel-height: 220px;
  --listing-card-skeleton-content-padding: var(--space-4x);
  --listing-card-skeleton-content-gap: var(--space-4x);
  --listing-card-preview-group-columns: 1fr;
  padding: var(--space-3x) var(--space-6x) var(--space-6x);
  --listing-card-preview-info-card-shadow: initial;
  --horizontal-scroll-list-container-height: 362px;
  overflow: auto;
  --listing-card-wrapper-height: 100%;
  --horizontal-scroll-buttons-container-top: -29px;
  --button-rounded-border-radius: var(
    --thank-you-similar-properties-button-border-radius
  );

  @media (--screen-sm) {
    --listing-card-skeleton-height: 342px;
    --listing-card-skeleton-carousel-height: 160px;
    --listing-card-preview-group-columns: repeat(2, minmax(0, 1fr));
  }

  @media (--screen-md) {
    --listing-card-skeleton-height: 327px;
    --listing-card-skeleton-carousel-height: 145px;
    --heading-font-size: var(--font-size-lg);
    padding: var(--space-3x) var(--space-8x) var(--space-10x);
  }

  @media (--screen-lg) {
    --listing-card-preview-group-columns: repeat(3, minmax(0, 1fr));
  }
}

/* This can be removed if once the rent_pdp_design_updates test is finished
and this value can be added to the Rent theme if the test succeeds */
.similarPropertiesUpdate {
  --thank-you-similar-properties-button-border-radius: var(
    --border-radius-full
  );
}

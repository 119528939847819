/*
@contract mobile-apps-page {
  background: BackgroundColor
  color: Color
}

@contract product-detail-section {
  order: Order
}
*/

.mobileAppsMainContainer {
  align-items: center;
  background: var(--mobile-apps-page-background);
  color: var(--mobile-apps-page-color);
  display: flex;
  flex-direction: column;
  margin: auto;
}

.mobileAppsContainer {
  max-width: 428px;

  @media (--screen-md) {
    max-width: 375px;
  }

  @media (--screen-lg) {
    max-width: 926px;
  }
}

.downloadCodeContainer {
  --heading-color: var(--mobile-apps-page-color);
  display: flex;
  flex-direction: column;
  margin-top: var(--space-10x);
  padding: 0 var(--space-6x) 0 var(--space-6x);

  @media (--screen-lg) {
    flex-direction: row;
    padding: 0;
  }
}

.textDivider {
  background: var(--color-indigo-400);
  height: var(--space-2x);
  margin-top: var(--space-8x);
  width: var(--space-10x);
}

.downloadCodeImage {
  height: 100%;
  margin: 0 auto calc(var(--space-7x) + var(--space-0-5x)) auto;
  position: relative;
  width: 327px;

  @media (--screen-lg) {
    margin-bottom: 0;
    margin-right: calc(var(--space-11x) + var(--space-10x));
    width: 400px;
  }
}

.downloadCodeBody {
  font-size: var(--font-size-body);
  margin: var(--space-8x) 0;
  color: var(--mobile-apps-description-text-color);
  @media (--screen-lg) {
    font-size: var(--font-size-lg);
    margin: var(--space-6x) 0;
  }
}

.getAppButton {
  margin-bottom: var(--space-6x);

  @media (--screen-md) {
    display: none;
  }
}

.downloadCodeCTAContainer {
  align-items: center;
  display: flex;
  margin-bottom: var(--space-6x);
}

.downloadCodeQR {
  display: none;
  margin-right: var(--space-6x);

  @media (--screen-md) {
    display: block;
  }
}

.downloadCodeText {
  display: none;
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);

  @media (--screen-md) {
    display: block;
  }
}

.sectionDivider {
  border: 1px solid var(--color-gray-400);
  border-top: none;
  margin: var(--space-16x) 0;

  @media (--screen-lg) {
    margin: var(--space-14x) 0;
  }
}

.midTextContainer {
  --heading-color: var(--mobile-apps-page-color);
  --heading-line-height: var(--leading-tight);
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0 var(--space-6x) 0 var(--space-6x);

  @media (--screen-lg) {
    margin-top: var(--space-16x);
  }
}

.midTextBody {
  margin-top: var(--space-3x);
  text-align: center;
  color: var(--mobile-apps-description-text-color);
}

.productImage {
  height: 100%;
  width: 375px;
  margin: auto;

  @media (--screen-lg) {
    margin: inherit;
  }
}

.productDetailsContainer {
  & section:nth-of-type(even) .productImage {
    @media (--screen-lg) {
      order: var(--product-detail-section-order);

      & ~ .productDetailText {
        padding-right: var(--space-6x);
        padding-left: 0;
      }
    }
  }

  & section:last-of-type {
    padding-bottom: var(--space-16x);
    margin-bottom: var(--space-16x);
    border-bottom: 1px solid var(--mobile-apps-border-color);

    @media (--screen-lg) {
      padding-bottom: var(--space-14x);
      margin-bottom: var(--space-14x);
    }
  }
}

.downloadCodeOutroContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: var(--space-16x);
  padding-bottom: var(--space-28x);

  @media (--screen-md) {
    padding-bottom: calc(var(--space-16x) + var(--space-0-5x));
    margin-top: 0;
  }
}

.outroDivider {
  margin-top: var(--space-16x);
  padding: 0 var(--space-6x) 0 var(--space-6x);

  @media (--screen-md) {
    margin-top: 0;
  }
}

.downloadCodeOutroHeader {
  font-size: var(--font-size-4xl);
  line-height: 1.1;
  margin-top: var(--space-3x);
}

.downloadCodeOutroBody {
  font-size: var(--font-size-body);
  line-height: 1.5;
  margin-bottom: var(--space-8x);
  margin-top: var(--space-8x);
  max-width: 384px;
  padding: 0 var(--space-6x);
  text-align: center;
  color: var(--mobile-apps-description-text-color);
  @media (--screen-md) {
    padding: 0;
  }
}

/* Utils */
.hiddenInLarge {
  @media (--screen-lg) {
    display: none;
  }
}

/* App Rating Module */

.quoteContainer {
  --rating-size: 22px;
  display: flex;
  flex-direction: column;
  margin: auto var(--space-6x);

  @media (--screen-md) {
    margin: auto var(--space-12x);
  }

  @media (--screen-lg) {
    margin: auto 0;
  }
}

.quoteHeader {
  font-family: var(--font-family-body);
  line-height: 1.1;
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-semibold);
  letter-spacing: -0.02em;
  padding-bottom: var(--space-10x);
}

.starClass {
  margin-top: calc(var(--space-9x) + var(--space-0-5x));
  margin-bottom: var(--space-1-5x);
}

.quoteOwnerText {
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-body);
}

.additionalElementsContainer {
  margin-top: var(--space-8x);
  font-size: var(--font-size-body);
  color: var(--mobile-apps-description-text-color);
}

/* Product Details Module */

.productDetailContainer {
  display: flex;
  flex-direction: column;

  @media (--screen-lg) {
    flex-direction: row;
  }
}

.productDetailOuterSpace {
  margin-top: var(--space-8x);
}

.productDetailInnerSpace {
  margin-top: var(--space-28x);
}

.productDetailText {
  display: flex;
  margin-top: var(--space-8x);

  @media (--screen-lg) {
    padding-left: var(--space-6x);
  }
}

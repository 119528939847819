.listingCardsStacked {
  max-width: 296px;
  height: 307px;
  margin: var(--space-10x) auto var(--space-6x) auto;
  position: relative;
  --listing-card-preview-card-background-color-hover-light: var(
    --listing-card-preview-card-background-color-hover-light
  );

  --listing-card-carousel-height: 177px;
  --listing-card-carousel-width: 100%;

  @media (--screen-md) {
    margin-top: var(--space-14x);
  }
  --listing-card-info-section-height: 130px;
}

.stackedSkeletons {
  --listing-card-skeleton-carousel-height: var(--space-44x);
  --listing-card-min-height: 0;
  --listing-card-skeleton-height: 307px;
  --listing-card-skeleton-content-gap: var(--space-5x);
}

.activeCard {
  position: relative;
  height: 100%;
  z-index: var(--z-index-5x);
  &:hover {
    box-shadow: 0 4px 8px -2px rgb(0 0 0 / 10%);
  }
}

.hiddenListingCard {
  position: absolute;
  width: 100%;
  height: var(--space-72x);
  max-width: 296px;
  border-radius: var(--listing-card-preview-border-radius);
  transform-origin: top;
  transform: translateX(-50%);
  left: 50%;
  top: 0;
}

.activeCard .hiddenListingCard {
  z-index: var(--z-index-4x);
}

.activeCard .hiddenListingCard:nth-of-type(2) {
  z-index: var(--z-index-3x);
}

.activeCard .hiddenListingCard:nth-of-type(3) {
  z-index: var(--z-index-2x);
}

.activeCard .hiddenListingCard:nth-of-type(4) {
  z-index: var(--z-index-1x);
}

.notInterested,
.leadSubmitted {
  position: absolute;
  opacity: 1;
}

.notInterested {
  animation: slide-left ease-out 0.35s;
}

@keyframes slide-left {
  from {
    margin-left: 0;
    top: 0;
    opacity: 1;
    z-index: 8;
  }
  to {
    margin-left: -200px;
    top: 75px;
    opacity: 0;
    z-index: 0;
  }
}

.leadSubmitted {
  animation: slide-right ease-out 0.35s;
}

@keyframes slide-right {
  from {
    margin-left: 0;
    top: 0;
    opacity: 1;
    z-index: 8;
  }
  to {
    margin-left: 200px;
    top: 75px;
    opacity: 0;
    z-index: 0;
  }
}

.backgroundCard {
  position: absolute;
  width: 100%;
  height: var(--space-72x);
  max-width: 296px;
  border-radius: var(--listing-card-preview-border-radius);
  transform-origin: top;
  transform: translateX(-50%);
  left: 50%;
}

.backgroundCard:nth-of-type(2) {
  background-color: var(--color-gray-200);
  top: calc(var(--space-3-5x) * -1);
  width: 272px;
  z-index: var(--z-index-4x);
}

.backgroundCard:nth-of-type(3) {
  background-color: var(--color-gray-100);
  top: calc(var(--space-7x) * -1);
  width: 248px;
  z-index: var(--z-index-3x);
}

.backgroundCard:nth-of-type(4) {
  background-color: var(--color-gray-100);
  opacity: 0.4;
  top: calc((var(--space-10x) + var(--space-0-5x)) * -1);
  width: var(--space-56x);
  z-index: var(--z-index-2x);
}

@layer components {
  .backDrop {
    width: 100vw;
    height: 100vh;
    inset: 0;
    position: fixed;
    background: var(--backdrop-background-color);
    opacity: 0;
    transition: opacity 150ms ease-in;
    pointer-events: none;
    z-index: var(--z-index-1x);
    display: var(--backdrop-background-display);
  }

  .backDropVisible {
    pointer-events: all;
    opacity: var(--backdrop-background-opacity);
  }

  .backDropEnter {
    transition: opacity 250ms ease-out;
  }

  .backDropEnterFrom {
    opacity: 0;
  }

  .backDropEnterTo {
    opacity: var(--backdrop-background-opacity);
  }

  .backDropLeave {
    transition: opacity 300ms ease-out;
  }

  .backDropLeaveFrom {
    opacity: var(--backdrop-background-opacity);
  }

  .backDropLeaveTo {
    opacity: 0;
  }
}

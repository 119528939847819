.ctaButtons {
  display: flex;
  justify-content: space-around;
  max-width: 296px;
  margin: 0 auto;
}

.interested,
.notInterested {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.interested {
  min-width: var(--space-32x);
}

.notInterested {
  padding-right: var(--space-4x);
  padding-left: var(--space-4x);
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--space-12x);
  height: var(--space-12x);
  border-radius: var(--border-radius-full);
}

.notInterested .iconWrapper {
  border: 2px solid var(--button-outlined-base-color);
  color: var(--button-outlined-base-color);
}

.interested .iconWrapper {
  background-color: var(--button-background-color-base);
}

.interested:hover .iconWrapper {
  background-color: var(--button-background-color-hover);
}

.notInterested:hover .iconWrapper {
  border-color: var(--button-outlined-color-hover);
  color: var(--button-outlined-color-hover);
}

.interestedIcon,
.notInterestedIcon {
  font-size: var(--font-size-xl);
}

.interestedIcon {
  background-color: interit;
  color: white;
}

.notInterestedIcon {
  background-color: --button-outlined-background-color;
}

.ctaLabel {
  font-size: var(--font-size-body);
  margin-top: var(--space-3x);
  color: var(--color-gray-500);
}

.tourDetails {
  font-size: var(--font-size-sm);
  text-align: center;
}

.timeRequest {
  color: var(--color-gray-500);
}

.tourTimeAndDay {
  font-weight: var(--font-weight-semibold);
  color: var(--color-black);
}

.message {
  display: flex;
  flex-direction: column;
}

.buttonDetailsWrapper {
  height: 143px;
}
